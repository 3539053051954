import { type GetPlanungsinformationOutput } from '../../../../dtos';
import { AccordionWithHeading } from '../../../general/AccordionWithHeading';
import { ContentLoading } from '../../../general/ContentLoading';
import { Tablelike } from '../../../general/Tablelike';
import { type TerminProps } from '../utils';
import { getThirdSectionTableRows } from './getThirdSectionTableRows';
import { content } from './TerminPlanungsinformationenCard.content';
import { getQuartalTableRows, getSecondSectionTableRows, printDauer } from './TerminPlanungsinformationenCard.utils';
import { Draggable } from '@mobiscroll/react';
import { Stack } from '@mui/material';

export const TerminPlanungsinformationenCard: React.FC<TerminProps> = (props: TerminProps) => {
  const { planungsinformationQuery } = props;

  if (planungsinformationQuery.isError) {
    throw new Error(planungsinformationQuery.error.message);
  }

  if (planungsinformationQuery.isLoading || !planungsinformationQuery.data) {
    return (
      <Draggable id={content.draggableId}>
        <AccordionWithHeading heading={content.heading}>
          <ContentLoading />
        </AccordionWithHeading>
      </Draggable>
    );
  }

  const planungsinformation: GetPlanungsinformationOutput = planungsinformationQuery.data;

  const secondSectionTableRows = getSecondSectionTableRows(planungsinformation);
  const thirdSectionTableRows = getThirdSectionTableRows(planungsinformation);

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading}>
        <Stack sx={{ paddingTop: 2 }}>
          <Tablelike withDivider xsLeft={6} rows={[[content.fields.dauer, printDauer(planungsinformation)], ...getQuartalTableRows(planungsinformation)]} />
          <Tablelike withDivider={thirdSectionTableRows.length > 0} xsLeft={6} rows={secondSectionTableRows} />
          <Tablelike xsLeft={6} rows={thirdSectionTableRows} />
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
