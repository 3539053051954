import { content as terminContent } from '../content';

export const content = {
  ...terminContent,
  draggableId: 'eigenschaften',
  heading: 'Eigenschaften',
  fields: {
    vivaStatus: 'Viva-Status:',
    geschaeftsbereich: 'Geschäftsbereich:',
    terminart: 'Terminart',
  },
  vivaStatus: {
    INPLANUNG: 'In Planung',
    ABGESCHLOSSEN: 'Planung abgeschlossen',
    FREIGEGEBEN: 'Planung freigegeben',
  },
  geschaeftsbereich: {
    muationSuccess: 'Geschäftsbereich erfolgreich geändert.',
    mutationError: 'Der Geschäftsbereich konnte nicht geändert werden.',
  },
} as const;
