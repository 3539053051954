export const content = {
  loadingMessage: 'Lädt ...',
  noInfoAvailable: '-',
  units: {
    tage: 'Tage',
    tag: 'Tag',
    qm: 'qm',
    percent: '%',
  },
  ja: 'ja',
  nein: 'nein',
} as const;
