import {
  type ChangeStatusForVeranstaltungenOutput,
  type Geschaeftsbereich,
  geschaeftsbereiche,
  type Veranstaltung,
  veranstaltungChangeFailedCause,
  type VivaStatus,
  vivaStatus as vivaStatusEnum,
} from '../../../../dtos';
import { trpc } from '../../../../trpc';
import { printTerminart } from '../../../../utils/printTerminart';
import { AccordionWithHeading } from '../../../general/AccordionWithHeading';
import { EditButtonGroup } from '../../../general/EditButtonGroup';
import { Tablelike } from '../../../general/Tablelike';
import { AlreadyCorrectMessageSingle, failBeMessage, FailCheckMessageSingle, failDbMessage, SuccessMessageSingle } from '../../statusChangeMessages.utils';
import { TextOrSelect } from './TextOrSelect';
import { content } from './VeranstaltungEigenschaftenCard.content';
import { Draggable } from '@mobiscroll/react';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

type VeranstaltungEigenschaftenCardProps = {
  readonly veranstaltung: Veranstaltung;
};

export const VeranstaltungEigenschaftenCard: React.FC<VeranstaltungEigenschaftenCardProps> = ({ veranstaltung }: VeranstaltungEigenschaftenCardProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditMode, setIsEditMode] = useState(false);
  const [vivaStatus, setVivaStatus] = useState<VivaStatus | undefined | null>();
  const [geschaeftsbereich, setGeschaeftsbereich] = useState<Geschaeftsbereich | undefined>(geschaeftsbereiche.KFF);

  const veranstaltungUtils = trpc.useUtils().veranstaltung;
  const changeStatusMutation = trpc.planung.changeStatusForVeranstaltungen.useMutation({
    onError: () => {
      enqueueSnackbar(failBeMessage, { variant: 'error' });
    },
    onSuccess: (mutateResult: ChangeStatusForVeranstaltungenOutput) => {
      void veranstaltungUtils.invalidate();
      if (mutateResult.success || mutateResult.cause === veranstaltungChangeFailedCause.HEALTH_CHECK) {
        if (mutateResult.successfullyChangedVeranstaltungen.length === 1) {
          enqueueSnackbar(<SuccessMessageSingle veranstaltung={mutateResult.successfullyChangedVeranstaltungen[0]} />, { variant: 'success' });
        }

        if (mutateResult.alreadyInCorrectStatusVeranstaltungen.length === 1) {
          enqueueSnackbar(<AlreadyCorrectMessageSingle veranstaltung={mutateResult.alreadyInCorrectStatusVeranstaltungen[0]} />, { variant: 'info' });
        }

        if (mutateResult.changeFailedVeranstaltungen.length === 1) {
          enqueueSnackbar(<FailCheckMessageSingle veranstaltung={mutateResult.changeFailedVeranstaltungen[0]} />, { variant: 'error' });
        }
      } else if (mutateResult.cause === veranstaltungChangeFailedCause.DATABASE) {
        enqueueSnackbar(failDbMessage, { variant: 'error' });
      }
    },
    onSettled: () => {
      setVivaStatus(veranstaltung.vivaStatus);
    },
  });

  const changeGeschaeftsbereichMutation = trpc.veranstaltung.mutateGeschaeftsbereich.useMutation({
    onSuccess: () => {
      void veranstaltungUtils.invalidate();
      enqueueSnackbar({ variant: 'success', message: content.geschaeftsbereich.muationSuccess });
    },
    onError: () => {
      enqueueSnackbar({ variant: 'error', message: content.geschaeftsbereich.mutationError });
      setGeschaeftsbereich(veranstaltung.geschaeftsbereich);
    },
  });

  useEffect(() => {
    setVivaStatus(veranstaltung.vivaStatus);
    setGeschaeftsbereich(veranstaltung.geschaeftsbereich);
  }, [veranstaltung]);

  const onSave = async (): Promise<void> => {
    if (vivaStatus !== veranstaltung.vivaStatus && typeof vivaStatus !== 'undefined' && vivaStatus !== null) {
      await changeStatusMutation.mutateAsync({
        newStatus: vivaStatus,
        idsToChange: [veranstaltung.id],
      });
    }

    if (geschaeftsbereich !== veranstaltung.geschaeftsbereich && typeof geschaeftsbereich !== 'undefined') {
      await changeGeschaeftsbereichMutation.mutateAsync({
        veranstaltungId: veranstaltung.id,
        geschaeftsbereich,
      });
    }
  };

  const onCancel = (): void => {
    setVivaStatus(veranstaltung.vivaStatus);
    setGeschaeftsbereich(veranstaltung.geschaeftsbereich);
  };

  return (
    <Draggable id={content.draggableId}>
      <AccordionWithHeading heading={content.heading}>
        <Stack paddingLeft={2} spacing={1}>
          {veranstaltung.vivaStatus && veranstaltung.vivaStatus !== vivaStatusEnum.FREIGEGEBEN && (
            <EditButtonGroup isEditMode={isEditMode} setEditMode={setIsEditMode} onSave={onSave} onCancel={onCancel} />
          )}
          <Tablelike
            rows={[
              [
                content.fields.vivaStatus,
                <TextOrSelect
                  key={v4()}
                  isEdit={isEditMode}
                  valueState={[vivaStatus, setVivaStatus]}
                  selections={[
                    { value: vivaStatusEnum.INPLANUNG, name: content.vivaStatus.INPLANUNG },
                    { value: vivaStatusEnum.ABGESCHLOSSEN, name: content.vivaStatus.ABGESCHLOSSEN },
                    { value: vivaStatusEnum.FREIGEGEBEN, name: content.vivaStatus.FREIGEGEBEN },
                  ]}
                />,
              ],
              [
                content.fields.geschaeftsbereich,
                <TextOrSelect
                  key={v4()}
                  isEdit={false}
                  valueState={[geschaeftsbereich, setGeschaeftsbereich]}
                  selections={[
                    { value: geschaeftsbereiche.IHC, name: geschaeftsbereiche.IHC },
                    { value: geschaeftsbereiche.ILS, name: geschaeftsbereiche.ILS },
                    { value: geschaeftsbereiche.KFF, name: geschaeftsbereiche.KFF },
                    { value: geschaeftsbereiche.CAS, name: geschaeftsbereiche.CAS },
                    { value: geschaeftsbereiche.DLS, name: geschaeftsbereiche.DLS },
                    { value: undefined, name: content.noInfoAvailable },
                  ]}
                />,
              ],
              [content.fields.terminart, printTerminart(veranstaltung)],
            ]}
          />
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
