import { content as terminContent } from '../content';

export const content = {
  ...terminContent,
  draggableId: 'planungsstatus',
  heading: 'Planungsstatus (aus ContentServ)',
  fields: {
    planungszeitraum: 'Planungszeitraum:',
    workflowStatus: 'Workflow-Status:',
    agilePlanerin: 'Agile:r Planer:in:',
    termineImPlanungsintervall1: 'Termine im Planungsintervall',
    termineImPlanungsintervall2: '(ist / soll)',
    manuelleTerminanfrage: 'Manuelle Terminanfrage:',
  },
  workflowStatusLabels: {
    produktmanagement: 'Produktmanagement',
    vaOrga: 'VA-Orga',
    planungAbgeschlossen: 'Planung abgeschlossen',
    planungInaktiv: 'Planung inaktiv',
  },
  snackBarSuccessManuell: 'Terminanfrage auf manuell gestellt',
  snackBarSuccessAutomatisch: 'Terminanfrage auf automatisch gestellt',
  snackBarFail: 'Terminanfrage konnte nicht gespeichert werden.',
} as const;
