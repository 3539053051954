import { lazy, Suspense, useEffect, useState } from 'react';

const ReactQueryDevtoolsForProduction: React.FC = () => {
  const [showReactQueryDevtools, setShowReactQueryDevtools] = useState(false);

  const LazyLoadedReactQueryDevtoolsProduction = lazy(
    async () =>
      await import('@tanstack/react-query-devtools/production').then((devTools) => ({
        default: devTools.ReactQueryDevtools,
      })),
  );

  useEffect(() => {
    // @ts-expect-error dynamically load ReactQuery devtools in production by calling `window.toggleReactQueryDevtools()`
    window.toggleReactQueryDevtools = (): void => setShowReactQueryDevtools((old) => !old);
  }, []);

  return (
    <div>
      {showReactQueryDevtools && (
        <Suspense fallback={null}>
          <LazyLoadedReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </div>
  );
};

export { ReactQueryDevtoolsForProduction };
