export const content = {
  headerNames: {
    id: 'AKA-Veranstaltung-ID',
    sapid: 'SAP-ID',
    start: 'Startdatum',
    end: 'Enddatum',
    status: 'Status',
    regionName: 'Region',
    ortKuerzel: 'Ortkürzel',
    experten: 'Trainer',
    terminart: 'Terminart',
    fehler: 'Fehlerstatus',
  },
  buttons: {
    abgeschlossen: 'auf "Planung abgeschlossen" setzen',
    freigegeben: 'auf "Planung freigegeben" setzen',
  },
  keineVeranstaltungen: 'Keine Veranstaltungen ausgewählt',
} as const;
