import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

export type EasyBreadcrumbsItem = {
  readonly label: string;
  readonly href: string;
};

type EasyBreadcrumbsProps = {
  readonly items: EasyBreadcrumbsItem[];
};

export const EasyBreadcrumbs: React.FC<EasyBreadcrumbsProps> = (props: EasyBreadcrumbsProps) => (
  <Breadcrumbs aria-label="breadcrumb">
    {props.items.map((item) => (
      <Link key={item.label} to={item.href}>
        {item.label}
      </Link>
    ))}
  </Breadcrumbs>
);
