import { cservVcTools, type GetPlanungsinformationOutput } from '../../../../dtos';
import { getQuartalsForDateRange, type Quartal, quartal } from '../../../../utils/getQuartalsForDateRange';
import { type TablelikeRow } from '../../../general/Tablelike';
import { content } from './TerminPlanungsinformationenCard.content';
import { endOfQuarter, isSameDay, startOfQuarter } from 'date-fns';

export const printDauer = (planungsinformation: GetPlanungsinformationOutput): string => {
  const dauerInTagen = planungsinformation.dauerInTagen;
  let print = '';

  if (dauerInTagen === null) {
    return print + content.noInfoAvailable;
  }

  if (dauerInTagen > 1) {
    print += dauerInTagen + ' ' + content.units.tage;
  } else {
    print += dauerInTagen + ' ' + content.units.tag;
  }

  return print;
};

const printQuartalterminLabel = (chosenQuartal: Quartal, year?: number): string => `${content.fields.termine} ${chosenQuartal}${year ? ' ' + year : ''}:`;

const printQuartaltermine = (planungsinformation: GetPlanungsinformationOutput, chosenQuartal: Quartal): string => {
  let geplanteTermine: number | null = null;

  switch (chosenQuartal) {
    case quartal.Q1:
      geplanteTermine = planungsinformation.geplanteTermineQ1;
      break;
    case quartal.Q2:
      geplanteTermine = planungsinformation.geplanteTermineQ2;
      break;
    case quartal.Q3:
      geplanteTermine = planungsinformation.geplanteTermineQ3;
      break;
    case quartal.Q4:
      geplanteTermine = planungsinformation.geplanteTermineQ4;
      break;
    default:
      break;
  }

  let printNumberOfGeplanteTermine: string = content.noInfoAvailable;

  if (geplanteTermine !== null) {
    printNumberOfGeplanteTermine = geplanteTermine.toString();
  }

  return printNumberOfGeplanteTermine;
};

const printRaumgroesse = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.raumgroesse === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.raumgroesse + ' ' + content.units.qm;
  }

  return print;
};

const printTeilnehmer = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.teilnehmerErwartet === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.teilnehmerErwartet;
  }

  return print;
};

const printBestuhlungsart = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.sitzordnung === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.sitzordnung;
  }

  return print;
};

const printGruppenraum = (gruppenraumNotwendig: number[]): string => {
  let print = '';

  for (const [index, day] of gruppenraumNotwendig.entries()) {
    print += content.units.tag + ' ' + day;

    if (index === gruppenraumNotwendig.length - 1) {
      continue;
    }

    print += ', ';
  }

  return print;
};

const printTool = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.vcTool === null) {
    print += content.noInfoAvailable;
  } else {
    switch (planungsinformation.vcTool) {
      case cservVcTools.MS_TEAMS:
        print += content.tools.MS_TEAMS;
        break;
      case cservVcTools.LUX:
        print += content.tools.LUX;
        break;
      case cservVcTools.TECHCAST:
        print += content.tools.TECHCAST;
        break;
      case cservVcTools.TRICAT:
        print += content.tools.TRICAT;
        break;
      case cservVcTools.ZOOM:
        print += content.tools.ZOOM;
        break;
      default:
        print += content.noInfoAvailable;
        break;
    }
  }

  return print;
};

export const printTerminreihe = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.istTerminreihe === null) {
    print += content.noInfoAvailable;
    return print;
  }

  if (planungsinformation.istTerminreihe) {
    print += content.ja;
  } else {
    print += content.nein;
  }

  return print;
};

export const printVerknuepfung = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.verknuepfungBLOPundPraesenzseminar === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.verknuepfungBLOPundPraesenzseminar;
  }

  return print;
};

export const getQuartalTableRows = (planungsinformation: GetPlanungsinformationOutput): TablelikeRow[] => {
  const { planungsbeginn, planungsende } = planungsinformation;

  if (planungsbeginn === null || planungsende === null) {
    return [
      [printQuartalterminLabel(quartal.Q1), printQuartaltermine(planungsinformation, quartal.Q1)],
      [printQuartalterminLabel(quartal.Q2), printQuartaltermine(planungsinformation, quartal.Q2)],
      [printQuartalterminLabel(quartal.Q3), printQuartaltermine(planungsinformation, quartal.Q3)],
      [printQuartalterminLabel(quartal.Q4), printQuartaltermine(planungsinformation, quartal.Q4)],
    ];
  }

  const startOfQuarterForGivenStartDate = startOfQuarter(planungsbeginn);
  const endOfQuarterForGivenEndDate = endOfQuarter(planungsende);
  const isStartDateEqualQuarterStart = isSameDay(startOfQuarterForGivenStartDate, planungsbeginn);
  const isEndDateEqualQuarterEnd = isSameDay(endOfQuarterForGivenEndDate, planungsende);

  if (!isStartDateEqualQuarterStart || !isEndDateEqualQuarterEnd) {
    return [
      [printQuartalterminLabel(quartal.Q1), printQuartaltermine(planungsinformation, quartal.Q1)],
      [printQuartalterminLabel(quartal.Q2), printQuartaltermine(planungsinformation, quartal.Q2)],
      [printQuartalterminLabel(quartal.Q3), printQuartaltermine(planungsinformation, quartal.Q3)],
      [printQuartalterminLabel(quartal.Q4), printQuartaltermine(planungsinformation, quartal.Q4)],
    ];
  }

  const quartalsList = getQuartalsForDateRange(planungsbeginn, planungsende);

  return quartalsList.map((listItem) => [printQuartalterminLabel(listItem.quartal, listItem.year), printQuartaltermine(planungsinformation, listItem.quartal)]);
};

export const getSecondSectionTableRows = (planungsinformation: GetPlanungsinformationOutput): TablelikeRow[] => {
  const rows: TablelikeRow[] = [
    [content.fields.raumgroesse, printRaumgroesse(planungsinformation)],
    [content.fields.teilnehmer, printTeilnehmer(planungsinformation)],
    [content.fields.bestuhlungsart, printBestuhlungsart(planungsinformation)],
  ];

  if (planungsinformation.gruppenraumNotwendig !== null && planungsinformation.gruppenraumNotwendig.length > 0) {
    rows.push([content.fields.gruppenraum, printGruppenraum(planungsinformation.gruppenraumNotwendig)]);
  }

  if (planungsinformation.vcTool !== null) {
    rows.push([content.fields.tool, printTool(planungsinformation)]);
  }

  return rows;
};
