import { type GetVeranstaltungByIdOutput } from '../../dtos';
import { useNormalizedParams } from '../../routingHooks';
import { trpc } from '../../trpc';
import { type TRPCClientErrorBase } from '@trpc/client';
import { type UseTRPCQueryResult } from '@trpc/react-query/shared';
// no risk, no fun ;)
// TODO: change this import path as soon as the new TRPC is declared stable
import { type DefaultErrorShape } from '@trpc/server/unstable-core-do-not-import';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Reroute: React.FC = () => {
  const navigate = useNavigate();
  const { veranstaltungId } = useNormalizedParams();

  const veranstaltungQuery: UseTRPCQueryResult<GetVeranstaltungByIdOutput, TRPCClientErrorBase<DefaultErrorShape>> = trpc.veranstaltung.getVeranstaltungById.useQuery({
    id: Number(veranstaltungId),
  });

  useEffect(() => {
    if (!veranstaltungQuery.isLoading && veranstaltungQuery.data) {
      const themaSapId = veranstaltungQuery.data.thema?.themaSapId;
      if (themaSapId && veranstaltungId) {
        const redirectPath = `/thema/${themaSapId}/veranstaltung/${veranstaltungId}`;
        navigate(redirectPath);
      }
    }
  }, [navigate, veranstaltungId, veranstaltungQuery]);

  if (veranstaltungQuery.isLoading) {
    return <div>Daten werden geladen...</div>;
  }

  if (veranstaltungQuery.isError) {
    return <div>Error beim Laden der Daten.</div>;
  }

  if (typeof veranstaltungQuery.data?.thema?.themaSapId !== 'number') {
    return <div>Für diese Veranstaltung konnte kein Thema gefunden werden.</div>;
  }

  return <div>Weiterleitung...</div>;
};

export default Reroute;
