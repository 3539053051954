import { type GetPlanungsinformationOutput } from '../../../../dtos';
import { content } from './TerminZeitlicherAblaufCard.content';

export const printZusatzinfo = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.zusatzinfoAblauf === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.zusatzinfoAblauf;
  }

  return print;
};
