import { content as terminContent } from '../content';

export const content = {
  ...terminContent,
  draggableId: 'ansprechpartnerin',
  heading: 'Ansprechpartner:innen (aus ContentServ)',
  fields: {
    pm: 'Verantwortliche:r PM:',
    fachkraft: 'Verantwortliche Fachkraft:',
    teamPlanning: 'Verantwortliche:r Team Planning:',
  },
} as const;
