/* eslint-disable react/prop-types */
import { type Zeitraeume } from '../../../dtos';
import { formatTimeRangeForDate } from '../../../utils/dateFormat';
import { Stack, Typography } from '@mui/material';

type BlockungenTextStackProps = {
  zeitraeume: Zeitraeume;
};

export const BlockungenTextStack: React.FC<BlockungenTextStackProps> = (props) => (
  <Stack spacing={1}>
    {props.zeitraeume.map(({ start, end }) => (
      <Typography key={Number(start)}>{formatTimeRangeForDate(start, end)}</Typography>
    ))}
  </Stack>
);
