import { VivaPageLoading } from '../components/general/VivaPageLoading';

const createRedirectUri = (urlHashPart: string): string => {
  const hashValues = decodeURIComponent(urlHashPart).split('&');
  const state = hashValues.find((value) => value.startsWith('state='));

  const states = state?.split('|');
  if (states && states.length === 2) {
    const redirectUri = states[1];
    return `${redirectUri}${urlHashPart}`;
  } else {
    return `/${urlHashPart}`;
  }
};

const isRedirectAllowed = (uri: string): boolean => {
  const allowedPatterns = [
    /^\//u,
    /^http:\/\/localhost:4444/u,
    /^https:\/\/va\.vamos\.ban\.haufe-akademie\.de/u,
    /^https:\/\/va\.vamos\.ban\.akadev\.de/u,
    /^https:\/\/va-dev-.{1,29}\.vamos\.ban\.akadev\.de/u,
  ];

  return allowedPatterns.some((pattern) => pattern.test(uri));
};

export const AuthRedirectPage: React.FC = () => {
  const redirectUri = createRedirectUri(window.location.hash);

  if (isRedirectAllowed(redirectUri)) {
    window.location.replace(redirectUri);
  } else {
    throw new Error('redirect blocked', { cause: `redurect uri: ${redirectUri}` });
  }

  return <VivaPageLoading msg="redirecting ..." />;
};
