import { type BackendConfigJson } from '../utils/BackendInfrastructure';
import { type BrowserAuthOptions, type RedirectRequest } from '@azure/msal-browser';

type MsalConfig = Omit<BackendConfigJson, 'AppBackendUrl'>;
export const msalConfig = (config: MsalConfig) => {
  const auth: BrowserAuthOptions = {
    clientId: config.AzureAdClientId ?? '',
    authority: `https://login.microsoftonline.com/${config.AzureAdTenantId ?? ''}`,
    redirectUri: config.AzureAdRedirectUri,
    navigateToLoginRequestUrl: true,
  };

  return {
    auth,
  };
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'offline_access', 'profile', 'email'],
  state: window.location.href,
};
