import { ContentLoading } from './ContentLoading';
import { NoContentInfo } from './NoContentInfo';
import { Alert, Stack } from '@mui/material';
import { type UseTRPCMutationResult, type UseTRPCQueryResult } from '@trpc/react-query/shared';
import { type ReactNode } from 'react';

type TrpcLoadingInfoProps = {
  readonly entity: string;
  readonly trpcQuery: UseTRPCQueryResult<unknown, unknown> | UseTRPCMutationResult<unknown, unknown, unknown, unknown>;
  readonly errorText?: string;
  readonly children?: ReactNode | ReactNode[];
};

const isEmpty = (data: unknown): boolean => {
  if (data === null || data === undefined) {
    return true;
  }

  if (Array.isArray(data)) {
    return data.length === 0;
  }

  if (typeof data === 'object') {
    return Object.keys(data).length === 0;
  }

  return false;
};

export const TrpcLoadingInfo: React.FC<TrpcLoadingInfoProps> = ({ entity, trpcQuery, errorText, children }: TrpcLoadingInfoProps) =>
  trpcQuery.isSuccess && !isEmpty(trpcQuery.data) ? (
    <>{children}</>
  ) : (
    <Stack direction="row" justifyContent="center">
      {trpcQuery.isPending && <ContentLoading />}
      {trpcQuery.isError && <Alert severity="error">{errorText ?? `Fehler beim Laden von ${entity}`}</Alert>}
      {trpcQuery.isSuccess && <NoContentInfo text={`Keine ${entity} gefunden`} />}
    </Stack>
  );
