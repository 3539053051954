import colorPalette from '../../theme/colorPalette';
import { Box, Stack, Typography } from '@mui/material';

export const FOOTER_HEIGHT = 8 as const;

export const Footer: React.FC = () => (
  <Box sx={{ mt: 2, height: `${FOOTER_HEIGHT}vh`, bgcolor: colorPalette.monochrome.oxfordBlue }}>
    <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ height: '100%', pr: 2 }}>
      <img alt="Haufe Akademie Logo" src="/assets/images/haufe/Haufe_Akademie_Logo_negativ.svg" style={{ maxHeight: '4vh' }} />
      <Typography color="white" variant="body2">
        powered by Team VAMOS
      </Typography>
    </Stack>
  </Box>
);
