import { Stack, TextField } from '@mui/material';
import { type KeyboardEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const HomePage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      navigate(`/search/thema?searchTerm=${searchTerm}`);
    }
  };

  return (
    <div>
      <Stack alignItems="center" direction="row" justifyContent="center" sx={{ marginTop: 10, marginBottom: 10, height: '60vh' }}>
        <TextField label="Suche" onChange={(event) => setSearchTerm(event.target.value)} onKeyDown={handleKeyDown} sx={{ width: '50vw' }} value={searchTerm} variant="outlined" />
      </Stack>
    </div>
  );
};
