import { type AkaVeranstaltungId, type ChangeStatusForVeranstaltungenOutput, type GetVeranstaltungByIdOutput, veranstaltungChangeFailedCause, vivaStatus } from '../../../../dtos';
import { trpc } from '../../../../trpc';
import { experteName } from '../../../../utils/experteUtils';
import { type GridFilterModelExtended } from '../../../../utils/MuiSchemas';
import { printTerminart } from '../../../../utils/printTerminart';
import { TrpcLoadingInfo } from '../../../general/TrpcLoadingInfo';
import { VaStatusChip } from '../../../general/VaStatusChip';
import { AlreadyCorrectMessageSeveral, failBeMessage, FailCheckMessageSeveral, failDbMessage, SuccessMessageSeveral } from '../../statusChangeMessages.utils';
import { FehlerCell } from './FehlerCell';
import { content } from './VeranstaltungListing.content';
import { getSlotBezeichnung, loadFilterModel, persistDataGridModel } from './VeranstaltungListing.utils';
import { Button, Grid2, Stack } from '@mui/material';
import { DataGrid, type GridColDef, type GridFilterModel, type GridRowSelectionModel, type GridSortModel } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { z } from 'zod';

const veranstaltungsColumns: Array<GridColDef<GetVeranstaltungByIdOutput>> = [
  { field: 'id', headerName: content.headerNames.id, flex: 0.7, valueGetter: (_value, row): AkaVeranstaltungId => row.akaVeranstaltungId },
  { field: 'sapid', headerName: content.headerNames.sapid, flex: 0.7 },
  { field: 'start', headerName: content.headerNames.start, flex: 0.5, type: 'date' },
  { field: 'end', headerName: content.headerNames.end, flex: 0.5, type: 'date' },
  {
    field: 'status',
    headerName: content.headerNames.status,
    flex: 1,
    renderCell: (params) => <VaStatusChip sapStatus={params.row.statusSap} vivaStatus={params.row.vivaStatus} />,
  },
  { field: 'regionName', headerName: content.headerNames.regionName, flex: 1 },
  { field: 'ortKuerzel', headerName: content.headerNames.ortKuerzel, renderCell: (params) => `${params.row.ortKuerzel ?? ''}${getSlotBezeichnung(params.row)}`, flex: 1 },
  {
    field: 'experten',
    headerName: content.headerNames.experten,
    renderCell: (params): string => {
      const experteNamen = params.row.experteBlockungen.map((experteBlockung) => experteName(experteBlockung.experte)).join(', ');
      if (!experteNamen) {
        return 'Unbekannt';
      }

      return experteNamen;
    },
    flex: 1,
  },
  {
    field: 'terminart',
    headerName: content.headerNames.terminart,
    renderCell: (params) => printTerminart(params.row),
    flex: 1,
  },
  {
    field: 'fehler',
    headerName: content.headerNames.fehler,
    renderCell: FehlerCell,
    flex: 0.5,
  },
];

type VeranstaltungListingProps = {
  readonly themaId: number;
  readonly openEdit: (vaId: number) => void;
};

export const VeranstaltungListing: React.FC<VeranstaltungListingProps> = ({ openEdit, themaId }: VeranstaltungListingProps) => {
  const vaQuery = trpc.veranstaltung.findByThemaId.useQuery({ themaId });
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = vaQuery;
  const changeStatusMutation = trpc.planung.changeStatusForVeranstaltungen.useMutation({
    onError: () => enqueueSnackbar(failBeMessage, { variant: 'error' }),
    onSuccess: (mutateResult: ChangeStatusForVeranstaltungenOutput) => {
      if (mutateResult.success || mutateResult.cause === veranstaltungChangeFailedCause.HEALTH_CHECK) {
        if (mutateResult.successfullyChangedVeranstaltungen.length > 0) {
          enqueueSnackbar(<SuccessMessageSeveral veranstaltungen={mutateResult.successfullyChangedVeranstaltungen} />, { variant: 'success' });
        }

        if (mutateResult.alreadyInCorrectStatusVeranstaltungen.length > 0) {
          enqueueSnackbar(<AlreadyCorrectMessageSeveral veranstaltungen={mutateResult.alreadyInCorrectStatusVeranstaltungen} />, { variant: 'info' });
        }

        if (mutateResult.changeFailedVeranstaltungen.length > 0) {
          enqueueSnackbar(<FailCheckMessageSeveral veranstaltungen={mutateResult.changeFailedVeranstaltungen} />, { variant: 'error' });
        }

        void refetch();
      } else if (mutateResult.cause === veranstaltungChangeFailedCause.DATABASE) {
        enqueueSnackbar(failDbMessage, { variant: 'error' });
      }
    },
  });

  const [gridSelection, setGridSelection] = useState<GridRowSelectionModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModelExtended | undefined>(loadFilterModel());
  const [sortModel, setSortModel] = useState<GridSortModel>([{ sort: 'desc', field: 'start' }]);
  const handleChangeStatusForSelectedVAs = async (newStatus: typeof vivaStatus.ABGESCHLOSSEN | typeof vivaStatus.FREIGEGEBEN): Promise<void> => {
    if (!gridSelection || gridSelection.length === 0) {
      enqueueSnackbar(content.keineVeranstaltungen, { variant: 'warning' });
      return;
    }

    await changeStatusMutation.mutateAsync({
      newStatus,
      idsToChange: z.array(z.coerce.number()).parse(gridSelection),
    });
  };

  return (
    <Grid2 container>
      <Grid2 size={{ xs: 12 }}>
        <Grid2 container justifyContent="end" sx={{ paddingBottom: 1 }}>
          {gridSelection && gridSelection.length > 0 && (
            <Grid2 size={{ xs: 3 }}>
              <Button disabled={changeStatusMutation.isPending} variant="outlined" onClick={async () => await handleChangeStatusForSelectedVAs(vivaStatus.ABGESCHLOSSEN)}>
                {content.buttons.abgeschlossen}
              </Button>
              <Button disabled={changeStatusMutation.isPending} variant="outlined" onClick={async () => await handleChangeStatusForSelectedVAs(vivaStatus.FREIGEGEBEN)}>
                {content.buttons.freigegeben}
              </Button>
            </Grid2>
          )}
        </Grid2>
        <Stack spacing={1}>
          <TrpcLoadingInfo trpcQuery={vaQuery} entity="Veranstaltungen">
            {data && (
              <DataGrid
                columns={veranstaltungsColumns}
                rows={data}
                onRowClick={(row) => openEdit((row.row as GetVeranstaltungByIdOutput).id)}
                rowHeight={80}
                checkboxSelection
                pageSizeOptions={[5, 10, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                filterModel={filterModel as unknown as GridFilterModel}
                onFilterModelChange={(newModel) => {
                  setFilterModel(newModel);
                  persistDataGridModel('filter', newModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newModel) => {
                  setSortModel(newModel);
                  persistDataGridModel('sort', newModel);
                }}
                onRowSelectionModelChange={(newSelection) => setGridSelection(newSelection)}
                rowSelectionModel={gridSelection}
              />
            )}
          </TrpcLoadingInfo>
        </Stack>
      </Grid2>
    </Grid2>
  );
};
