const weekdayFormatter = new Intl.DateTimeFormat('de-DE', { weekday: 'long', timeZone: 'Europe/Berlin' });
const dateFormatter = new Intl.DateTimeFormat('de-DE', { dateStyle: 'medium', timeZone: 'Europe/Berlin' });
const timeFormatter = new Intl.DateTimeFormat('de-DE', { timeStyle: 'short', timeZone: 'Europe/Berlin' });

/*
 * Singles
 */

// Donnerstag
const formatWeekday = (date: Date) => weekdayFormatter.format(date);

// 22.08.2024
export const formatDate = (date?: Date | null) => (date ? dateFormatter.format(date) : '-');

// 09:00
export const formatTime = (date?: Date | null) => (date ? timeFormatter.format(date) : '-');

/*
 * Ranges
 */

// 22.08.2024, 09:00 - 17:00
export const formatTimeRangeForDate = (start: Date, end: Date) => `${formatDate(start)}, ${formatTime(start)} - ${formatTime(end)}`;

// Donnerstag, 22.08.2024 09:00 - 17:00
export const formatTimeRangeForDateWithWeekday = (start: Date, end: Date) => `${formatWeekday(start)}, ${formatDate(start)} ${formatTime(start)} - ${formatTime(end)}`;

// 22.08.2024 - 31.03.2026
export const formatDateRange = (start: Date | string, end: Date | string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};
