import { content as terminContent } from '../content';

export const content = {
  ...terminContent,
  draggableId: 'experte',
  heading: 'Trainer:innen | Moderator:innen',
  fields: {
    name: 'Name:',
    rolle: 'Rolle:',
    sapId: 'SapId',
    zeitraum: 'Zeitraum:',
    kommunikationspartner: 'Kommunikationspartner:',
    manuelleTerminanfrage: 'Manuelle Terminanfrage:',
    statusTerminanfrageHive: 'Status Terminanfrage (Hive):',
  },
  terminanfrageHiveStatus: {
    manual: {
      label: 'manuell',
      tooltip:
        'Hive hat die Blockung entgegengenommen und bestätigt, dass es keine Konflikte mit anderen Terminen gibt. Die Terminanfrage muss manuell durchgeführt werden und wird nicht automatisiert an AK(A)tivity weitergeleitet.',
    },
    pending: {
      label: 'angefragt',
      tooltip: 'Automatisierte Terminanfrage an Trainer:in in AK(A)tivity weitergeleitet.',
    },
    accepted: {
      label: 'angenommen',
      tooltip: 'Automatisierte Terminanfrage von Trainer:in in AK(A)tivity angenommen.',
    },
    rejected: (additional: string) => ({
      label: `abgelehnt, Grund: ${additional}`,
      tooltip: 'Automatisierte Terminanfrage von Trainer:in in AK(A)tivity abgelehnt.',
    }),
    conflict: (additional: string) => ({
      label: `Konflikte mit ${additional}`,
      tooltip: 'Terminanfrage konnte nicht angenommen werden, da bereits eine Blockung durch andere Termine besteht. Bitte in Klärung gehen und den Konflikt auflösen.',
    }),
    cancelled: {
      label: 'storniert',
      tooltip: 'Die Terminanfrage wurde bereits storniert. Die gesamte Trainerblockung wird in Kürze gelöscht.',
    },
  },
} as const;
