import { type SelectedExperte } from '../../dtos';
import { trpc } from '../../trpc';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

type ExperteSearchAutocompleteProps = {
  readonly onSelect: (experte: SelectedExperte | null) => void;
};

export const ExperteSearchAutocomplete: React.FC<ExperteSearchAutocompleteProps> = ({ onSelect }: ExperteSearchAutocompleteProps) => {
  const [searchInput, setSearchInput] = useState('');
  const [selectedExperte, setSelectedExperte] = useState<SelectedExperte | null>(null);
  const experteQuery = trpc.planung.resources.getExperteByNachnameOrSapId.useQuery(searchInput, { staleTime: 1_000 });

  return (
    <Autocomplete
      disablePortal
      onInputChange={(_, value) => {
        setSearchInput(value);
      }}
      sx={{ minWidth: 400 }}
      value={selectedExperte}
      options={experteQuery.data ?? []}
      renderInput={(params) => <TextField {...params} label="Trainersuche" />}
      getOptionLabel={(experte) => `${experte.nachname}, ${experte.vorname}`}
      renderOption={(props, experte) => (
        <li {...props}>
          <Stack sx={{ marginLeft: 1, marginBottom: 1 }}>
            <Typography variant="body1">
              {experte.nachname}, {experte.vorname}
            </Typography>
            <Typography variant="caption">ID: {experte.experteSapId}</Typography>
          </Stack>
        </li>
      )}
      filterOptions={(x) => x}
      onChange={(_, value) => {
        onSelect(value);
        setSelectedExperte(value);
      }}
    />
  );
};
