import { z } from 'zod';

const gridLogicOperatorSchema = z.any();

export const gridFilterModelExtendedSchema = z
  .object({
    items: z.array(
      z
        .object({
          field: z.string(),
          operator: gridLogicOperatorSchema,
          value: z.unknown().optional(),
          id: z.union([z.string(), z.number()]).optional(),
        })
        .strict(),
    ),
    logicOperator: gridLogicOperatorSchema.optional(),
    quickFilterValues: z.array(z.unknown()).optional(),
    quickFilterLogicOperator: gridLogicOperatorSchema.optional(),
    quickFilterExcludeHiddenColumns: z.boolean().optional(),
  })
  .strict();

export type GridFilterModelExtended = z.infer<typeof gridFilterModelExtendedSchema>;
