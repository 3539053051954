import { useDroppable } from '@dnd-kit/core';
import { Box } from '@mui/material';
import { type ReactNode } from 'react';

type DroppableProps = { readonly children: ReactNode; readonly id: string };

export const Droppable: React.FC<DroppableProps> = (props: DroppableProps) => {
  const { setNodeRef, isOver } = useDroppable({ id: props.id });

  return (
    <Box ref={setNodeRef} sx={{ height: '100%', backgroundColor: isOver ? 'rgba(155, 255, 131, 0.1)' : undefined }}>
      {props.children}
    </Box>
  );
};
