import { type Veranstaltung } from '../../../../dtos';
import { type GridFilterModelExtended, gridFilterModelExtendedSchema } from '../../../../utils/MuiSchemas';

export const getSlotBezeichnung = (veranstaltung: Veranstaltung): string => (veranstaltung.slotBezeichnung ? ` (${veranstaltung.slotBezeichnung})` : '');

export const persistDataGridModel = (type: 'filter' | 'sort', model: unknown): void => {
  localStorage.setItem(`/thema:${type}`, JSON.stringify(model));
};

export const loadFilterModel = (): GridFilterModelExtended | undefined => {
  const filterModel = localStorage.getItem('/thema:filter');
  return filterModel ? gridFilterModelExtendedSchema.parse(JSON.parse(filterModel)) : undefined;
};
