import { getQuarter } from 'date-fns/getQuarter';
import { z } from 'zod';

export const quartal = {
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4',
} as const;
const quartalSchema = z.nativeEnum(quartal);
export type Quartal = z.infer<typeof quartalSchema>;

const getQuartalFromQuartalNr = (quartalNr: number): Quartal => {
  let q = null;

  switch (quartalNr) {
    case 1:
      q = quartal.Q1;
      break;
    case 2:
      q = quartal.Q2;
      break;
    case 3:
      q = quartal.Q3;
      break;
    case 4:
      q = quartal.Q4;
      break;
    default:
      throw new Error(`Can't calculate Quartal from Quartal Nr. ${quartalNr}`);
  }

  return q;
};

export const getQuartalsForDateRange = (
  date1: Date,
  date2: Date,
): Array<{
  quartal: Quartal;
  year: number;
}> => {
  let start: Date;
  let end: Date;
  if (date1 < date2) {
    start = date1;
    end = date2;
  } else {
    start = date2;
    end = date1;
  }

  const startQuartalNr = getQuarter(start);
  const endQuartalNr = getQuarter(end);

  const startQuartalYear = start.getFullYear();

  const endQuartalYear = end.getFullYear();

  const quartalList = [];

  let quartalCnt = startQuartalNr;
  let yearCnt = startQuartalYear;

  while ((quartalCnt <= endQuartalNr && yearCnt === endQuartalYear) || yearCnt < endQuartalYear) {
    quartalList.push({
      quartal: getQuartalFromQuartalNr(quartalCnt),
      year: yearCnt,
    });

    quartalCnt++;
    if (quartalCnt > 4) {
      quartalCnt = 1;
      yearCnt++;
    }
  }

  return quartalList;
};
