import { type Clusterzuordnung, type Keyify, type ResourcenBlockungType } from '../../../../../dtos';

const clusterzuordnungLabels = {
  haupttrainer: 'Haupttrainer',
  nebentrainer: 'Nebentrainer',
  ersatztrainer: 'Ersatztrainer',
} as const satisfies Record<Clusterzuordnung, string>;

const calenderResourceGroupLabels = {
  EXPERTE: 'Trainer',
  RAUM: 'Räume',
  SLOT: 'Standorte (Slots)',
} as const satisfies Record<Keyify<ResourcenBlockungType>, string>;

export const content = {
  clusterzuordnungLabels,
  calenderResourceGroupLabels,
} as const;
