import { Alert, Stack } from '@mui/material';

type NoContentInfoProps = {
  readonly text: string;
};

export const NoContentInfo: React.FC<NoContentInfoProps> = (props: NoContentInfoProps) => (
  <Stack justifyContent="center" alignContent="center" padding={2} height="100%">
    <Stack direction="row" justifyContent="center">
      <Alert severity="info">{props.text}</Alert>
    </Stack>
  </Stack>
);
