import { Chip, Stack, Typography } from '@mui/material';

export const LegendChips: React.FC = () => (
  <Stack alignItems="center" direction="row" spacing={2}>
    <Typography variant="body1">Legende:</Typography>
    {/* <Chip label='belegt' /> */}
    <Chip color="success" label="buchbare Zeiträume" sx={{ backgroundColor: '#DAFAC2', color: 'inherit' }} />
    <Chip color="warning" label="geblockt" />
    <Chip color="error" label="gebucht" />
    <Chip color="info" label="aktuelle Auswahl" sx={{ backgroundColor: '#075BFA' }} />
    <Chip label="bisherige Auswahl" sx={{ backgroundColor: 'gray', color: 'white' }} />
  </Stack>
);
