import { experteBlockungQuelle, geschaeftsbereiche, type Raum, resourcenBlockungTypes } from '../../../../../../../dtos';
import { type TerminInfo } from '../../../../../../../utils/TerminInfo';
import { CalendarRaumEvent } from '../CalendarEvent';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { addDays, eachDayOfInterval, endOfDay, isWithinInterval, max, min, startOfDay } from 'date-fns';

export class CalendarRaumResource extends AbstractCalendarResource {
  public readonly type = resourcenBlockungTypes.RAUM;

  public readonly id: `r-${string}`;

  public readonly name: string;

  public readonly data: Raum;

  public constructor(terminInfo: TerminInfo, raum: Raum) {
    super(terminInfo);
    this.data = raum;
    this.id = `r-${raum.roomId}`;
    this.name = `${raum.kuerzel} / ${raum.name} / ${raum.groesse} m² / ${raum.kapazitaet.uForm}-${raum.kapazitaet.parlamentarisch}-${raum.kapazitaet.ohneTische}`;
  }

  public toEvents(): CalendarRaumEvent[] {
    const events: CalendarRaumEvent[] = [];

    for (const blockung of this.data.bookedSlots) {
      const start = blockung.startDate;
      const end = blockung.endDate;

      const event = events.find((ev) => ev.data.terminId === String(blockung.veranstaltungsId));
      if (event) {
        event.start = min([event.start, start]);
        event.end = max([event.end, end]);
        event.data.ablauf.push({ start, end });
      } else {
        const newEvent = new CalendarRaumEvent(start, end, this, {
          ablauf: [],
          quelle: blockung.quelle,
          division: geschaeftsbereiche.KFF,
          terminId: String(blockung.veranstaltungsId),
        });

        const isOldSelection = blockung.veranstaltungsId === Number(this.terminInfo.id) || blockung.veranstaltungsId === Number(this.terminInfo.sapid);
        newEvent.isOldSelection = isOldSelection;

        events.push(newEvent);
      }
    }

    return events;
  }

  public createEvent(date: Date): CalendarRaumEvent {
    const end = addDays(date, this.terminInfo.ablauf.length - 1);
    const event = new CalendarRaumEvent(date, end, this, {
      ablauf: [],
      terminId: String(this.terminInfo.id ?? ''),
      quelle: experteBlockungQuelle.VAMOS,
      division: this.terminInfo.division,
    });

    return event;
  }

  public getDataId(): number {
    return this.data.roomId;
  }

  public isAvailable(interval: { start: Date; end: Date }) {
    const days = eachDayOfInterval(interval);
    return days.every((date) =>
      this.data.boughtAvailabilities.some((availability) => isWithinInterval(date, { start: startOfDay(availability.startDate), end: endOfDay(availability.endDate) })),
    );
  }
}
