import { Skeleton, Stack } from '@mui/material';

export const ContentLoading: React.FC = () => (
  <Stack direction="row" justifyContent="center" alignContent="center" width="100%" height="100%" padding={2}>
    <Stack justifyContent="center" width="100%" maxWidth="50vw" height="100%">
      <Skeleton data-testid="skeleton" />
      <Skeleton data-testid="skeleton" />
      <Skeleton data-testid="skeleton" />
    </Stack>
  </Stack>
);
