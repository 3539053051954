import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, type AccordionProps, AccordionSummary, Box, type BoxProps, Stack, Typography } from '@mui/material';
import { type ReactNode } from 'react';

type AccordionWithHeadingProps = {
  readonly heading: ReactNode;
  readonly subheading?: ReactNode;
  readonly children: ReactNode | ReactNode[];
  readonly boxProps?: BoxProps;
  readonly accordionProps?: Omit<AccordionProps, 'children'>;
};

export const AccordionWithHeading: React.FC<AccordionWithHeadingProps> = (props: AccordionWithHeadingProps) => (
  <Box {...props.boxProps}>
    <Accordion defaultExpanded disableGutters {...props.accordionProps}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Stack>
          {typeof props.heading === 'string' ? (
            <Typography variant="h6" component="h6">
              {props.heading}
            </Typography>
          ) : (
            props.heading
          )}
          {typeof props.subheading === 'string' ? <Typography sx={{ color: 'text.secondary' }}>{props.subheading}</Typography> : props.subheading}
        </Stack>
      </AccordionSummary>
      <AccordionDetails data-no-dnd="true">{props.children}</AccordionDetails>
    </Accordion>
  </Box>
);
