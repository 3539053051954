import { type ThemaFromThemaRouter, type Veranstaltung, vivaStatus } from '../../../dtos';
import { AccordionWithHeading } from '../../general/AccordionWithHeading';
import { Tablelike } from '../../general/Tablelike';
import { BlockungenTextStack } from './BlockungenTextStack';
import { ChangeResources } from './ChangeResources';
import { Draggable } from '@mobiscroll/react';
import { Stack } from '@mui/material';
import { v4 } from 'uuid';

type TerminOrtCardProps = {
  readonly thema: ThemaFromThemaRouter;
  readonly veranstaltung: Veranstaltung;
};

export const TerminOrtCard: React.FC<TerminOrtCardProps> = ({ thema, veranstaltung }: TerminOrtCardProps) => {
  const getOrtLabel = (): string => {
    const standortSlotLabel = veranstaltung.slotBezeichnung ? `Standort ${veranstaltung.slotBezeichnung}` : null;
    const ortLabel = veranstaltung.ortKuerzel && veranstaltung.ortBezeichnung ? `${veranstaltung.ortKuerzel} ${veranstaltung.ortBezeichnung}` : null;

    return standortSlotLabel ?? ortLabel ?? '';
  };

  const getStandortLabel = (): string => (veranstaltung.standortKuerzel && veranstaltung.standortName ? `${veranstaltung.standortKuerzel} ${veranstaltung.standortName}` : '');

  return (
    <Draggable id="ort">
      <AccordionWithHeading heading="Veranstaltungsort">
        <Stack spacing={1}>
          {veranstaltung.vivaStatus === vivaStatus.INPLANUNG && (
            <ChangeResources
              terminInfo={{
                id: veranstaltung.id,
                themaId: thema.id,
                division: veranstaltung.geschaeftsbereich,
                ablauf: veranstaltung.ablauf,
              }}
            />
          )}
          <Tablelike
            withDivider
            rows={[
              ['Region:', veranstaltung.regionName],
              ['Standort:', getStandortLabel()],
              ['Ort:', getOrtLabel()],
            ]}
          />
          {veranstaltung.raeume.map((raum) => (
            <Tablelike
              key={raum.sapid}
              withDivider
              rows={[
                ['Raum-Art:', <b key={v4()}>{raum.art[0].toLocaleUpperCase() + raum.art.slice(1)}</b>],
                ['Raum-ID:', raum.sapid],
                ['Raum-Name:', raum.name],
                ['Raum-Kürzel:', raum.kuerzel],
                ['Raum-Größe:', raum.groesse],
                ['Raum-Sitzordnung:', raum.sitzordnung ?? '-'],
                ['Zeitraum:', <BlockungenTextStack key={v4()} zeitraeume={raum.blockungen.map((blockung) => ({ start: blockung.startDateTime, end: blockung.endeDateTime }))} />],
              ]}
            />
          ))}
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
