import { type GetVeranstaltungByIdOutput, healthLevel } from '../../../../dtos';
import { Cancel, CheckCircle, Info, Warning } from '@mui/icons-material';
import { type GridRenderCellParams } from '@mui/x-data-grid';

export const FehlerCell: React.FC<GridRenderCellParams<GetVeranstaltungByIdOutput>> = (params: GridRenderCellParams<GetVeranstaltungByIdOutput>) => {
  switch (params.row.health) {
    case healthLevel.WARNING:
      return <Warning color="warning" />;
    case healthLevel.ERROR:
      return <Cancel color="error" />;
    case healthLevel.INFO:
      return <Info color="info" />;
    default:
      return <CheckCircle color="success" />;
  }
};
