import { Divider, Grid2, Typography } from '@mui/material';
import { type ReactNode } from 'react';
import { v4 } from 'uuid';

export type TablelikeRow = [ReactNode, ReactNode];
type TablelikeProps = {
  readonly heading?: ReactNode;
  readonly rows: TablelikeRow[];
  readonly withDivider?: boolean;
  readonly xsLeft?: number;
};

export const Tablelike: React.FC<TablelikeProps> = (props: TablelikeProps) => (
  <Grid2 container spacing={1}>
    {props.heading ? (
      <Grid2 size={{ xs: 12 }}>
        <Typography variant="h6" component="div">
          {props.heading}
        </Typography>
      </Grid2>
    ) : null}
    {props.rows.map(([fst, snd]) => (
      <Grid2 key={v4()} container size={{ xs: 12 }}>
        <Grid2 size={{ xs: props.xsLeft ?? 4 }} sx={{ overflowWrap: 'break-word', textOverflow: 'pretty' }}>
          <Typography component="div">{fst}</Typography>
        </Grid2>
        <Grid2 size={{ xs: props.xsLeft ? 12 - props.xsLeft : 8 }} alignItems="center">
          <Typography component="div">{snd}</Typography>
        </Grid2>
      </Grid2>
    ))}
    {props.withDivider ? (
      <Grid2 size={{ xs: 12 }} sx={{ marginBottom: 2, marginTop: 1 }}>
        <Divider />
      </Grid2>
    ) : null}
  </Grid2>
);
