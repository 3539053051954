import { type FachlicherFehler } from '../../dtos';
import { trpc } from '../../trpc';
import { Box } from '@mui/material';
import { DataGrid, gridClasses, type GridColDef } from '@mui/x-data-grid';

const columns: Array<GridColDef<FachlicherFehler>> = [
  { field: 'createdAt', type: 'dateTime', headerName: 'Fehler-Zeitstempel', flex: 0.5 },
  { field: 'veranstaltungBeginn', type: 'date', headerName: 'Veranstaltung-Beginn', flex: 0.6 },
  { field: 'veranstaltungSapId', headerName: 'Sap-E-Id', flex: 0.5 },
  { field: 'akaVeranstaltungId', headerName: 'AKA-VA-Id', flex: 0.5 },
  { field: 'quelle', headerName: 'Quelle', flex: 0.5, sortable: false },
  { field: 'veranstaltungTitel', headerName: 'Titel', flex: 1, sortable: false },
  { field: 'geschaeftsbereich', headerName: 'Geschäftsbereich', flex: 0.5, sortable: false },
  { field: 'code', headerName: 'Fehler-Typ', flex: 0.6, sortable: false },
  { field: 'message', headerName: 'Fehler-Beschreibung', flex: 1, sortable: false },
];

export const FehlerPage: React.FC = () => {
  const fehlerResult = trpc.fehler.getFachlicheFehler.useQuery();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', margin: 'auto', padding: 2 }}>
      <DataGrid
        columns={columns}
        rows={fehlerResult.data ?? []}
        getRowHeight={() => 'auto'}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
      />
    </Box>
  );
};
