import { type Clusterzuordnung, clusterzuordnung, type Keyify } from '../dtos';

export type ClusterzuordnungAbbreviation = 'A' | 'B' | 'C';

const clusterzuordnungAbbreviation = {
  HAUPTTRAINER: 'A', // cluster A
  NEBENTRAINER: 'B', // cluster B
  ERSATZTRAINER: 'C', // cluster C
} as const satisfies Record<Keyify<Clusterzuordnung>, ClusterzuordnungAbbreviation>;

export const getClusterzuordnungAbbreviation = (givenClusterzuordnung: Clusterzuordnung): ClusterzuordnungAbbreviation => {
  let clusterAbbreviation: ClusterzuordnungAbbreviation;

  switch (givenClusterzuordnung) {
    case clusterzuordnung.HAUPTTRAINER:
      clusterAbbreviation = clusterzuordnungAbbreviation.HAUPTTRAINER;
      break;
    case clusterzuordnung.NEBENTRAINER:
      clusterAbbreviation = clusterzuordnungAbbreviation.NEBENTRAINER;
      break;
    case clusterzuordnung.ERSATZTRAINER:
      clusterAbbreviation = clusterzuordnungAbbreviation.ERSATZTRAINER;
      break;
    default:
      throw new Error(`Could not find abbreviation for clusterzuordnung ${givenClusterzuordnung}`);
  }

  return clusterAbbreviation;
};
