import { resourcenBlockungTypes } from '../../../../../../../dtos';
import { type CalendarExperteEvent } from './CalendarExperteEvent';
import { type CalendarRaumEvent } from './CalendarRaumEvent';
import { type CalendarSlotEvent } from './CalendarSlotEvent';

export const isRaumEvent = (event: CalendarEvent): event is CalendarRaumEvent => event.type === resourcenBlockungTypes.RAUM;

export const isExperteEvent = (event: CalendarEvent): event is CalendarExperteEvent => event.type === resourcenBlockungTypes.EXPERTE;
export const isSlotEvent = (event: CalendarEvent): event is CalendarSlotEvent => event.type === resourcenBlockungTypes.SLOT;

export type CalendarEvent = CalendarExperteEvent | CalendarRaumEvent | CalendarSlotEvent;
