import { type ThemaFromSearchRouter } from '../../../../dtos';
import { RecentVeranstaltungen } from '../recentVeranstaltungen';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

type ThemaResultCardProps = {
  readonly thema: ThemaFromSearchRouter;
};

export const ThemaResultCard: React.FC<ThemaResultCardProps> = ({ thema }: ThemaResultCardProps) => (
  <Card>
    <CardContent>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column">
          <Typography variant="h5">{thema.titel}</Typography>
          <Typography variant="body2">SAP-ID: {thema.produktSapId}</Typography>
        </Stack>
        <Link to={`/thema/${thema.produktSapId}`}>
          <Button variant="contained">Zum Thema</Button>
        </Link>
      </Stack>
      <RecentVeranstaltungen themaId={thema.id} />
    </CardContent>
  </Card>
);
