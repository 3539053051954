import { content as terminContent } from '../content';

export const content = {
  ...terminContent,
  draggableId: 'eigenschaften',
  heading: 'Eigenschaften',
  fields: {
    akaProduktId: 'AKA-Produkt-ID:',
    sapProduktId: 'SAP-Produkt-ID:',
    sprache: 'Sprache:',
    format: 'Format:',
    letzteAenderung: 'Letzte Änderung in Viva:',
  },
} as const;
