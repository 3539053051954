import { type VeranstaltungWithErrorMessages } from '../../dtos';
import { Box } from '@mui/material';

type SingleProps = {
  readonly veranstaltung: VeranstaltungWithErrorMessages;
};

type SeveralProps = {
  readonly veranstaltungen: VeranstaltungWithErrorMessages[];
};

export const SuccessMessageSingle: React.FC<SingleProps> = (props: SingleProps) => (
  <Box>
    <p>Der Status für Veranstaltung {props.veranstaltung.id} wurde erfolgreich geändert!</p>
  </Box>
);

export const SuccessMessageSeveral: React.FC<SeveralProps> = (props: SeveralProps) => (
  <Box>
    <p>Status erfolgreich bei folgenden Veranstaltungen geändert:</p>
    <ul>
      {props.veranstaltungen.map((veranstaltung) => (
        <li key={veranstaltung.id}>{veranstaltung.id}</li>
      ))}
    </ul>
  </Box>
);

export const AlreadyCorrectMessageSingle: React.FC<SingleProps> = (props: SingleProps) => (
  <Box>
    <p>Der Status für Veranstaltung {props.veranstaltung.id} war bereits korrekt gesetzt.</p>
  </Box>
);

export const AlreadyCorrectMessageSeveral: React.FC<SeveralProps> = (props: SeveralProps) => (
  <Box>
    <p>Der Status war für folgende Veranstaltungen bereits korrekt gesetzt:</p>
    <ul>
      {props.veranstaltungen.map((veranstaltung) => (
        <li key={veranstaltung.id}>{veranstaltung.id}</li>
      ))}
    </ul>
  </Box>
);

const ListOfFailureReasons: React.FC<SingleProps> = (props: SingleProps) => (
  <ul>
    {props.veranstaltung.errorMessages.map((errorMessage) => (
      <li key={`${props.veranstaltung.id} - ${errorMessage}`}>{errorMessage}</li>
    ))}
  </ul>
);

export const FailCheckMessageSingle: React.FC<SingleProps> = (props: SingleProps) => (
  <Box>
    <p>Die Status für Veranstaltung {props.veranstaltung.id} konnte nicht geändert werden. </p>
    <ul>
      {props.veranstaltung.id}
      <ListOfFailureReasons veranstaltung={props.veranstaltung} />
    </ul>
  </Box>
);

export const FailCheckMessageSeveral: React.FC<SeveralProps> = (props: SeveralProps) => (
  <Box>
    <p>Die Status konnten für folgende Veranstaltungen nicht geändert werden: </p>
    <ul>
      {props.veranstaltungen.map((veranstaltung) => (
        <li key={veranstaltung.id}>
          {veranstaltung.id}
          <ListOfFailureReasons veranstaltung={veranstaltung} />
        </li>
      ))}
    </ul>
  </Box>
);

export const failDbMessage = 'Datenbank-Fehler beim Ändern der Status.';
export const failBeMessage = 'Backend-Fehler beim Ändern des Status.';
