import { z } from 'zod';

const backendConfigJsonSchema = z
  .object({
    AppBackendUrl: z.string(),
    AzureAdTenantId: z.string().optional(),
    AzureAdClientId: z.string().optional(),
    AzureAdRedirectUri: z.string().optional(),
    VivaAppURL: z.string().optional(),
    HiveAppUrl: z.string().optional(),
  })
  .strict();

export type BackendConfigJson = z.infer<typeof backendConfigJsonSchema>;

export type GetBackendConfigResult =
  | {
      success: true;
      config: BackendConfigJson;
    }
  | {
      success: false;
      errorCode: 'FETCH_FAILED' | 'PARSE_FAILED';
    };

let backendConfig: BackendConfigJson | undefined;

export const getBackendConfig = async (): Promise<GetBackendConfigResult> => {
  if (backendConfig) {
    return {
      success: true,
      config: backendConfig,
    } as const;
  }

  const envAppBackendUrl = import.meta.env.VITE_APP_BACKEND_URL;
  const envAzureAdTenantId = import.meta.env.VITE_APP_AUTH_TENANTID;
  const envAzureAdClientId = import.meta.env.VITE_APP_AUTH_CLIENTID;
  const envAzureAdRedirectUri = import.meta.env.VITE_APP_AUTH_REDIRECTURI;
  const vivaAppURL = import.meta.env.VITE_APP_VIVA_URL;
  const hiveAppUrl = import.meta.env.VITE_APP_HIVEAPPURL ?? 'https://hive.prml.ban.akadev.de/';
  if (envAppBackendUrl && window.location.hostname === 'localhost') {
    backendConfig = {
      AppBackendUrl: envAppBackendUrl,
      AzureAdClientId: envAzureAdClientId,
      AzureAdTenantId: envAzureAdTenantId,
      AzureAdRedirectUri: envAzureAdRedirectUri,
      VivaAppURL: vivaAppURL,
      HiveAppUrl: hiveAppUrl,
    };

    console.log('resolved config from env variables', { backendConfig });
    return {
      success: true,
      config: backendConfig,
    } as const;
  }

  const result = await fetch('/backend-config.json');
  if (result.status !== 200) {
    return {
      success: false,
      errorCode: 'FETCH_FAILED',
    } as const;
  }

  const config = await result.json();
  const parsedConfig = backendConfigJsonSchema.safeParse(config);
  if (!parsedConfig.success) {
    return {
      success: false,
      errorCode: 'PARSE_FAILED',
    } as const;
  }

  // eslint-disable-next-line require-atomic-updates
  backendConfig = parsedConfig.data;
  return {
    success: true,
    config: parsedConfig.data,
  } as const;
};

const fetchWithTimeout = async (resource: string, timeout: number) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    signal: controller.signal,
    mode: 'no-cors',
  });
  clearTimeout(id);

  return response;
};

export const checkIfBackendIsReachable = async (): Promise<boolean> => {
  const backCfg = await getBackendConfig();
  if (!backCfg.success) {
    return false;
  }

  try {
    await fetchWithTimeout(backCfg.config.AppBackendUrl, 4_000);
    return true;
  } catch {
    return false;
  }
};
